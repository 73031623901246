import type { FormInput } from '@/utils/client/forms/intervention/edit'
import type { Intervention, Item, Service } from '@laast-io/types'

import type { PartialDeep } from 'type-fest'
import type { FetchIntervention } from '~/composables/data/panels/interventions'
import type { FetchInterventionQuote } from '~/composables/data/quote/interventions'
import { addPrices } from './operators'

import {
  calculateSubtotalDiscount,
  calculateSubtotalPrice,
  calculateTaxesPrice,
  calculateTotalDiscount,
  calculateTotalPrice,
  type PriceData,
} from './price-data'

export function computeQuoteData(intervention?: PartialDeep<FormInput> | Intervention | FetchIntervention | FetchInterventionQuote | null) {
  let priceSubtotal = 0
  let priceTotal = 0
  let priceTotalItems = 0
  let priceTotalServices = 0
  let totalTaxes = 0
  let totalTaxesMargin = 0
  let totalDiscount = 0
  let totalDiscountItems = 0
  let totalDiscountServices = 0

  let priceSubtotalSpareParts = 0
  let priceSubtotalItems = 0
  let priceSubtotalServices = 0

  if (
    !intervention?.planned_services
    || !Array.isArray(intervention?.planned_services)
    || !Array.isArray(intervention?.items)
  ) {
    return {
      priceSubtotal,
      priceSubtotalSpareParts,
      priceSubtotalItems,
      priceSubtotalServices,
      priceTotal,
      totalTaxes,
      totalTaxesMargin,
      totalDiscount,
      totalDiscountItems,
      totalDiscountServices,
    }
  }

  for (const interventionItem of intervention.items) {
    if (typeof interventionItem !== 'object') {
      continue
    }

    const item = interventionItem?.item as Item | null
    if (!item?.id) {
      continue
    }

    const itemData: PriceData = {
      unitary_cost: item.unitary_cost,
      unitary_price: item.unitary_price,
      tax_ratio: item?.tax_ratio,
      quantity: interventionItem?.quantity ?? 1,
      discount: interventionItem.discount,
      discount_type: interventionItem.discount_type,
      is_second_hand: item?.is_second_hand,
    }

    const itemTotal = calculateTotalPrice(itemData)
    const itemTax = calculateTaxesPrice(itemData)
    const itemSubtotal = calculateSubtotalPrice(itemData)
    const itemTotalDiscount = calculateSubtotalDiscount(itemData)

    priceTotal = addPrices(priceTotal, itemTotal)
    priceTotalItems = addPrices(priceTotalItems, itemTotal)

    if (item?.is_second_hand) {
      totalTaxesMargin = addPrices(totalTaxesMargin, itemTax)
    }
    else {
      totalTaxes = addPrices(totalTaxes, itemTax)
    }

    priceSubtotal = addPrices(priceSubtotal, itemSubtotal)
    priceSubtotalItems = addPrices(priceSubtotalItems, itemSubtotal)
    totalDiscount = addPrices(totalDiscount, itemTotalDiscount)
    totalDiscountItems = addPrices(totalDiscountItems, calculateTotalDiscount(itemData))
  }

  for (const planned of intervention.planned_services) {
    const service = (planned?.service) as Service | null
    if (!service?.id) {
      continue
    }

    let priceModifiers = 0
    if (planned.require_spare_part) {
      for (const item of planned.require_spare_part) {
        if (item.price_modifier) {
          priceModifiers += item.price_modifier
        }
      }
    }

    const serviceData: PriceData = {
      unitary_price: service.unitary_price + priceModifiers,
      tax_ratio: service.tax_ratio,
      quantity: 1,
      discount: planned.discount,
      discount_type: planned.discount_type,
    }

    const serviceTotal = calculateTotalPrice(serviceData)
    const serviceTax = calculateTaxesPrice(serviceData)
    const serviceSubtotal = calculateSubtotalPrice(serviceData)
    const serviceTotalDiscount = calculateSubtotalDiscount(serviceData)

    priceTotal = addPrices(priceTotal, serviceTotal)
    priceTotalServices = addPrices(priceTotalServices, serviceTotal)
    totalTaxes = addPrices(totalTaxes, serviceTax)
    priceSubtotal = addPrices(priceSubtotal, serviceSubtotal)
    priceSubtotalServices = addPrices(priceSubtotalServices, serviceSubtotal)
    totalDiscount = addPrices(totalDiscount, serviceTotalDiscount)
    totalDiscountServices = addPrices(totalDiscountServices, calculateTotalDiscount(serviceData))

    if (service.require_spare_parts) {
      for (const item of service.require_spare_parts) {
        if (item.spare_part?.unitary_cost && item.quantity) {
          const cost
            = Math.round(item.spare_part.unitary_cost * 100 * item.quantity) / 100
          priceSubtotalSpareParts += cost
        }
      }
    }
  }

  return {
    priceTotal,
    priceTotalServices,
    priceTotalItems,
    priceSubtotal,
    priceSubtotalSpareParts,
    priceSubtotalItems,
    priceSubtotalServices,
    totalTaxes,
    totalTaxesMargin,
    totalDiscount,
    totalDiscountItems,
    totalDiscountServices,
  }
}
