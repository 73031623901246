import type { FormInput } from '@/utils/client/forms/intervention/edit'
import type { Intervention } from '@laast-io/types'
import type { PartialDeep } from 'type-fest'
import type { FetchIntervention } from './data/panels/interventions'
import type { FetchInterventionQuote } from './data/quote/interventions'

export function useTotalItemQuantity(value: MaybeRefOrGetter<PartialDeep<FormInput> | Intervention | FetchIntervention>) {
  return computed(() => {
    let total = 0
    const intervention = toValue(value)

    if (!intervention?.items || !Array.isArray(intervention?.items)) {
      return total
    }

    for (const item of intervention.items) {
      if (typeof item !== 'object') {
        continue
      }

      total += item.quantity
    }

    return total
  })
}

export function useServiceRequirementMap(value: MaybeRefOrGetter<PartialDeep<FormInput> | Intervention | FetchIntervention>) {
  return computed(() => {
    const intervention = toValue(value)
    return getServiceRequirementMap(intervention)
  })
}

export function useInterventionQuote(value: MaybeRefOrGetter<PartialDeep<FormInput> | Intervention | FetchIntervention | FetchInterventionQuote | null>) {
  return computed(() => {
    const intervention = toValue(value)
    return computeQuoteData(intervention)
  })
}

export function useInterventionEstimatedTime(value: MaybeRefOrGetter<PartialDeep<FormInput> | Intervention | FetchIntervention>) {
  return computed(() => {
    let time = 0
    const intervention = toValue(value)

    if (intervention?.planned_services && Array.isArray(intervention?.planned_services)) {
      for (const service of intervention.planned_services) {
        if (service?.service?.estimated_time) {
          time += service.service.estimated_time
        }
      }
    }

    return time
  })
}
